<template>
    <v-card>
        <v-card-title>FILTROS</v-card-title>
        <v-card-text>
            <v-row class="pas-2">
                <v-col class="" cols="12" sm="2">
                    <v-autocomplete item-text="text" item-value="id" v-model="tipoFiltro" :items="items"
                        label="Filtrar por"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition"
                        offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="firstDay" label="Fecha Inicio" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="firstDay" no-title scrollable @input="menu2 = false"> </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition"
                        offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="nextDay" label="Fecha Fin" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="nextDay" no-title scrollable @input="menu = false"> </v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                    <v-btn @click="getVentaByDate" :loading="loading" color="primary"> Buscar </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="optionalRole.isDownload">
                    <v-btn @click="downloadXlsx" :loading="loading" color="primary"> DESCARGAR XLSX </v-btn>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { format, subMonths, addMonths } from 'date-fns'
import { mapState, mapGetters } from 'vuex'
import { exportXLSX } from '@/utils'
import { formatNumber } from '@/utils/genericUtils'

export default {
    data() {
        return {
            isSelected: 2,
            menu: false,
            nextDay: null,
            firstDay: null,
            menu2: false,
            loading: false,
            tipoFiltro: 3,
            items: [
                { id: 3, text: 'Fecha Creacion' },
                { id: 4, text: 'Fecha Entrega/Finalizacion' }
            ]
        }
    },
    computed: {
        ...mapGetters('users', ['optionalRole']),

        ...mapState('ventas', ['listaVentaHisto']),
        ...mapState('users', ['user']),
        ...mapState('utilities', ['tipoMoneda', 'tipoFormaEntrega'])
    },
    methods: {
        async getVentaByDate() {
            //console.log('here')
            this.loading = true
            this.$store.commit('mainUI/OPEN_MODAL', { step: 1, state: true })
            await this.$store.dispatch('ventas/getReservas', { firstDay: this.firstDay, nextDay: this.nextDay, tipoFiltro: this.tipoFiltro })
            this.$store.commit('mainUI/OPEN_MODAL', { step: 1, state: false })
            this.loading = false
        },
        async downloadXlsx() {
            const items = []
            console.log('headers', this.listaVentaHisto)
            this.loading = true
            const headers = [
                'id',
                'descEstado',
                'numeroComprobante',
                'idOrdenCompra',
                'sedeDetail',
                'nombreColaborador',
                'fechaCreacion',
                'check_in',
                '_description',
                '_full_name',
                'idTipoMoneda',
                'importeTotal',
                'importeAbonadoSoles',
                'importeAbonadoDolares',
                'cotOC',
                'formaEntregaDesc'
            ]
            if (this.listaVentaHisto.length > 0) {
                //console.log('this.lista', this.lista)
                this.listaVentaHisto.map((v) => {
                    const tipoMoneda = this.tipoMoneda.find((x) => x.id == v.idTipoMoneda)
                    // //console.log('v.idTipoMoneda ', v.idTipoMoneda)
                    let productoFinal = ''
                    let nameProduct = ''
                    const valProducts = v.productos

                    if (valProducts.length > 0 && valProducts) {
                        productoFinal = valProducts[0]
                        if (typeof valProducts == 'string') {
                            // //console.log('Este es string')
                            productoFinal = JSON.parse(valProducts)
                            nameProduct = productoFinal.length > 0 ? productoFinal[0].description : ''
                        } else {
                            nameProduct = productoFinal.description
                        }
                    }
                    items.push({
                        ...v,
                        idTipoMoneda: v.idTipoMoneda == 1 ? 'S/.' : '$',
                        importeTotal: v.importeTotal,
                        efectivo: formatNumber(v.efectivo, v.idTipoMoneda),
                        tarjeta: formatNumber(v.tarjeta, v.idTipoMoneda),
                        transferencia: formatNumber(v.transferencia, v.idTipoMoneda),
                        adelanto: formatNumber(v.adelanto, v.idTipoMoneda),
                        cotOC: v.idCotizacion,
                        oldTicket: `${v.oldTicket || ''} ${v.oldTicketReserva || ''}`,
                        _id: `T-${v.id}`,
                        _finalizado: v.finalizado == 1 ? 'F' : 'P',
                        estado: v.finalizado,
                        _full_name: v.full_name ? v.full_name.slice(0, 25) : '',
                        _description: nameProduct,
                        formaEntregaDesc: v.formaEntrega ? this.tipoFormaEntrega.find((x) => x.id == v.formaEntrega)?.text : null
                    })
                })
            }
            console.log('items', items)
            const filename = `Historial Ventas ${this.firstDay} _ ${this.nextDay}`
            exportXLSX(
                {
                    headers: [
                        'TICKET',
                        'Estados',
                        'Numero de Comprobante',
                        'Orden de Compra',
                        'Sede',
                        'Vendedor',
                        'Fecha Creación',
                        'Fecha Entrega/Fin',
                        'Descripcion',
                        'Cliente',
                        'Tipo Monedo',
                        'Total a Pagar',
                        'Total Pagado (S/)',
                        'Total Pagado ($)',
                        'Cotizacion',
                        'FormaEntrega'
                    ],
                    campos: headers,
                    arrayData: items
                },
                { filename }
            )
            this.loading = false
        }
    },
    mounted() {
        //console.log('here')
        this.nextDay = format(addMonths(new Date(), 1), 'yyyy-MM-dd')
        this.firstDay = format(subMonths(new Date(), 1), 'yyyy-MM-dd')
    }
}
</script>
<style scoped>
.classText {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

@media only screen and (max-width: 768px) {
    .classText {
        font-size: 12px;
        word-wrap: break-word;
    }
}

@media only screen and (min-width: 768px) {
    .classText {
        font-size: 14px;
        word-wrap: break-word;
    }
}
</style>
